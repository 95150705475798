.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.url-input-text {
  height: 100%;
  flex-grow: 10;
}

.url-submit {
  flex-grow: 2;
}

.summarizer-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 80%;
  gap: 10px;
}
